import './App.css';
import banner from './boc-banner.webp'
import packageJson from '../package.json';
import React, { useEffect, useState, Fragment } from 'react'

function preloadImage (src: string) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = function() {
      resolve(img)
    }
    img.onerror = img.onabort = function() {
      reject(src)
    }
    img.src = src
  })
}
function App() {
  const [assetsLoaded, setAssetsLoaded] = useState<boolean>(false)
  useEffect(() => {
    let isCancelled = false

    async function effect() {
      if (isCancelled) {
        return
      }

      const imagesPromiseList: Promise<any>[] = []
      imagesPromiseList.push(preloadImage(banner))

      await Promise.all(imagesPromiseList)

      if (isCancelled) {
        return
      }

      setAssetsLoaded(true)
    }

    effect()

    return () => {
      isCancelled = true
    }
  }, [])
  return (
    <div className="App">
      <span className="version">{packageJson.version}</span>
      <header className="App-header">
        <h1>Julio TdS</h1>
        <div className="contacts">
          <div className="block">
            <div>
              <a aria-label="My Linkedin page" href="https://www.linkedin.com/in/julio-takagui-de-souza-226358198/" className="round linkedin">
                <svg className="logo" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                  <path fillRule="evenodd" d="M12.5 8.8v1.7a3.7 3.7 0 0 1 3.3-1.7c3.5 0 4.2 2.2 4.2 5v5.7h-3.2v-5c0-1.3-.2-2.8-2.1-2.8-1.9 0-2.2 1.3-2.2 2.6v5.2H9.3V8.8h3.2ZM7.2 6.1a1.6 1.6 0 0 1-2 1.6 1.6 1.6 0 0 1-1-2.2A1.6 1.6 0 0 1 6.6 5c.3.3.5.7.5 1.1Z" clipRule="evenodd"/>
                  <path d="M7.2 8.8H4v10.7h3.2V8.8Z"/>
                </svg>
              </a>
            </div>
          </div>
          <div className="block">
            <div>
              <a aria-label="My Email" href="mailto:takaguij@gmail.com" className="round mail">
                <svg className="logo" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M2 5.6V18c0 1.1.9 2 2 2h16a2 2 0 0 0 2-2V5.6l-.9.7-7.9 6a2 2 0 0 1-2.4 0l-8-6-.8-.7Z"/>
                  <path d="M20.7 4.1A2 2 0 0 0 20 4H4a2 2 0 0 0-.6.1l.7.6 7.9 6 7.9-6 .8-.6Z"/>
                </svg>
              </a>
            </div>
          </div>
          <div className="block">
            <div>
              <a aria-label="My GitHub page" href="https://github.com/Juliotds" className="round github">
              <svg className="logo" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                <path fillRule="evenodd" d="M12 2c-2.4 0-4.7.9-6.5 2.4a10.5 10.5 0 0 0-2 13.1A10 10 0 0 0 8.7 22c.5 0 .7-.2.7-.5v-2c-2.8.7-3.4-1.1-3.4-1.1-.1-.6-.5-1.2-1-1.5-1-.7 0-.7 0-.7a2 2 0 0 1 1.5 1.1 2.2 2.2 0 0 0 1.3 1 2 2 0 0 0 1.6-.1c0-.6.3-1 .7-1.4-2.2-.3-4.6-1.2-4.6-5 0-1.1.4-2 1-2.8a4 4 0 0 1 .2-2.7s.8-.3 2.7 1c1.6-.5 3.4-.5 5 0 2-1.3 2.8-1 2.8-1 .3.8.4 1.8 0 2.7a4 4 0 0 1 1 2.7c0 4-2.3 4.8-4.5 5a2.5 2.5 0 0 1 .7 2v2.8c0 .3.2.6.7.5a10 10 0 0 0 5.4-4.4 10.5 10.5 0 0 0-2.1-13.2A9.8 9.8 0 0 0 12 2Z" clipRule="evenodd"/>
              </svg>
              </a>
            </div>
          </div>
        </div>
      </header>
      { assetsLoaded?
      <Fragment>
        <section className="App-body">
          <div className="half-row">
            <div className='card-banner'>
              <h2>Placeholder</h2>
              <img src={banner} alt='banner' />
            </div>
            <div className='card-banner'>
              <h2>Placeholder</h2>
              <img src={banner} alt='banner' />
            </div>
          </div>
          <div className="half-row">
            <div className='card-banner'>
              <h2>Placeholder</h2>
              <img src={banner} alt='banner' />
            </div>
            <div className='card-banner'>
              <h2>Placeholder</h2>
              <img src={banner} alt='banner' />
            </div>
          </div>
        </section>
      </Fragment>
        :
      <Fragment>
        <section className="App-body">
          <div className="half-row">
            <div className='card-banner'>
              <h2>Placeholder</h2>
              <img src={''} alt='banner' />
            </div>
            <div className='card-banner'>
              <h2>Placeholder</h2>
              <img src={''} alt='banner' />
            </div>
          </div>
          <div className="half-row">
            <div className='card-banner'>
              <h2>Placeholder</h2>
              <img src={''} alt='banner' />
            </div>
            <div className='card-banner'>
              <h2>Placeholder</h2>
              <img src={''} alt='banner' />
            </div>
          </div>
        </section>
      </Fragment>
      }
    </div>
  );
}

export default App;
